<template>
  <notifications group="noti-popup" :max="1" :duration="5000">
    <template slot="body" slot-scope="props">
      <section id="popBanner" class="pop banner" style="display: flex">
        <a
          class="popBody"
          @click="
            popBannerClose();
            props.close();
          "
        >
          <span class="title">{{ props.item.title }}</span>
          <span v-html="props.item.text"></span>
        </a>
      </section>
    </template>
  </notifications>
</template>
<script>
export default {
  name: 'NotificationBar',
  components: {},
  data() {
    return {};
  },
  methods: {
    popBannerClose() {
      popBanner.children[0].style.animationName = 'bannerUp';
    },
  },
};
</script>
